import React from 'react';
import ReactDOM from 'react-dom';
// import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import GlobalService from 'services/GlobalService';
import { resturls } from 'globals/utils/apiurls';
import * as serviceWorker from './serviceWorker';
import './index.css';
// import i18n from './i18n';
import App from './App';

// ReactDOM.render(
//   <I18nextProvider i18n={i18n}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </I18nextProvider>,
//   document.getElementById('root'),
// );

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, rootElement);
} else {
  ReactDOM.hydrate(<BrowserRouter><App /></BrowserRouter>, rootElement);
}

let count = 1;
let myInterval;
function pageAwaitingCall() {
  return new Promise((resolve) => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('mLogId')) {
      const mLogId = urlParams.get('mLogId');
      GlobalService.generalSelect(
        (respdata) => {
          console.log(respdata);
          resolve(true);
        }, `${resturls.timeSpentURL}?timeSpent=${count}&mLogId=${mLogId}`, '', 'GET', 'app',
      );
    }
  });
}

function timerHandler() {
  count += 1;
  console.log('Time', count);
  if (count % 10 === 0) pageAwaitingCall();
}

// Start timer
function startTimer() {
  myInterval = window.setInterval(timerHandler, 1000);
}

// Stop timer
function stopTimer() {
  window.clearInterval(myInterval);
  pageAwaitingCall();
}

const urlParams = new URLSearchParams(window.location.search);
if (urlParams.has('mLogId')) {
  document.addEventListener('DOMContentLoaded', (event) => {
    startTimer();
    pageAwaitingCall();
  });
  window.addEventListener('focus', startTimer);
  window.addEventListener('blur', stopTimer);
  window.addEventListener('beforeunload', async (e) => {
    await pageAwaitingCall();
    console.log('after');
    return '';
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
