import React, { Component } from 'react';
import {
  Container, Row, Col, Nav,
} from 'react-bootstrap';
import GlobalService from 'services/GlobalService';
import { resturls } from 'globals/utils/apiurls';
import { eboxTitleName } from 'globals/utils/index';
import FBpixel from 'globals/utils/FBpixel';
import DefaultLoader from 'globals/components/loader/DefaultLoader';
import CourseTrackCard from 'screens/courseTrack/CourseTrackCard';
import './scss/RoleScreen.scss';

class RoleScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.data = { name: '', description: '', collCourses: [] };
  }

  componentDidMount = () => {
    this.loadData();
  }

  componentDidUpdate = (prevState) => {
    const { name } = this.data;
    if (prevState.name !== name) {
      this.enablePixel();
    }
  }

  enablePixel = () => {
    const { name } = this.data;
    FBpixel.trackCustom('ViewContent', {
      content_ids: [{ 'Category Name': name }],
    });
  }

  loadData = () => {
    GlobalService.generalSelect(
      (respdata) => {
        const { status, message, data } = respdata;
        if (status === true && message === 'success') {
          this.data = data;
          this.setState({ loading: false });
        }
      }, resturls.rolePage, { code: 'role' }, 'POST',
    );
  }

  render = () => {
    const { loading } = this.state;
    const { collCourses } = this.data;
    const { activeCatelogue } = this.props;
    eboxTitleName('E-Box | Role Based Course');
    const catlogNames = collCourses?.map((ele) => ele.name);
    let activeTab = 'Trainer Driven Programs';
    const catelogueTypeObj = {
      '#selfPacedPrograms': 'Self Paced Programs',
      '#trainerDrivenPrograms': 'Trainer Driven Programs',
    };
    if (catelogueTypeObj[activeCatelogue] === undefined) {
      activeTab = 'Trainer Driven Programs';
    } else {
      activeTab = catelogueTypeObj[activeCatelogue];
    }
    return (
      <>
        {(loading === false)
          ? (
            <div className="course-page-header">
              <section className="pgmsection commonsection">
                <Container>
                  <div className="sectionTitle text-center">Role Based Programs</div>
                  <div className="sectionpara centerPara text-center">Learn through job Role Based skilling programs like Full Stack Developer, React Developer, AI&ML, and Big Data that would be highly relevant in near future.</div>
                  <div className="catalogue-wrapper">
                    {
                      catlogNames.reverse().map((ele) => (
                        <>
                          <Nav.Link href={ele === 'Self Paced Programs' ? '/#selfPacedPrograms' : '/#trainerDrivenPrograms'}>
                            <div
                              id={ele === 'Self Paced Programs' ? '#selfPacedPrograms' : '#trainerDrivenPrograms'}
                              className={activeTab === ele ? 'active-catlog-button' : 'catalogue-button'}
                            >
                              {ele}
                            </div>
                          </Nav.Link>

                        </>
                      ))
                    }
                  </div>
                  {collCourses.filter(
                    (ele) => ele.name === activeTab,
                  ).map((cate) => (
                    <div key={cate.name} className="course-group">
                      <h4>{cate.name}</h4>
                      <Row>
                        {cate.courses.map((track) => (
                          <Col key={track.id} xs={12} sm={4} md={3}>
                            <CourseTrackCard trackDetail={track} />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                </Container>
              </section>
            </div>
          )
          : (
            <div>
              <DefaultLoader />
            </div>
          )}
      </>
    );
  }
}

export default RoleScreen;
